import { Injectable } from '@angular/core';
import { endOfDay, intervalToDuration, startOfDay } from 'date-fns';

import { DateInput, getDate } from '../utils/date/date-format';

import { PluralizeService } from './pluralize.service';

export interface DurationStringOptions {
  years?: boolean;
  months?: boolean;
  days?: boolean;
  hours?: boolean;
  minutes?: boolean;
  seconds?: boolean;
  dayStart?: boolean;
}

@Injectable({ providedIn: 'root' })
export class DurationService {
  private _messageMap = {
    years: 'pluralize.year',
    months: 'pluralize.month',
    days: 'pluralize.day',
    hours: 'pluralize.hour',
    minutes: 'pluralize.minute',
    seconds: 'pluralize.second',
  };

  constructor(private pluralize: PluralizeService) {}

  public asString(
    value1: DateInput | undefined,
    value2: DateInput | undefined = undefined,
    options: DurationStringOptions = {
      years: true,
      months: true,
      days: true,
    }
  ): string {
    const { dayStart } = options;
    const startValue = getDate(value1) ?? new Date();
    const endValue = getDate(value2) ?? new Date();
    const duration = intervalToDuration({
      start: dayStart ? startOfDay(startValue) : startValue,
      end: dayStart ? endOfDay(endValue) : endValue,
    });
    return Object.keys(options)
      .reduce<string[]>((result, key) => {
        const optionsKey = key as Exclude<keyof typeof options, 'dayStart'>;
        const durationKey = key as keyof typeof duration;
        if (options[optionsKey] && duration[durationKey]) {
          result.push(
            this.pluralize.pluralize(
              duration[durationKey]!,
              this._messageMap[optionsKey]
            )
          );
        }
        return result;
      }, [])
      .join(', ');
  }
}
