import { Injectable } from '@angular/core';
import { CrmTranslateService } from 'common-module/translate';

@Injectable({ providedIn: 'root' })
export class PluralizeService {
  constructor(private translate: CrmTranslateService) {}

  public pluralize(value: number, key: string): string {
    let _key = key;
    if (value >= 5) {
      _key += '5';
    } else if (value >= 2 && value <= 4) {
      _key += '24';
    }
    return this.translate.get(_key, { value });
  }
}
