import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes } from 'date-fns';

import {
  DurationService,
  DurationStringOptions,
} from '../services/duration.service';
import { FormatterService } from '../services/formatter.service';
import { DateInput } from '../utils/date/date-format';

@Pipe({ standalone: true, name: 'formatCustom' })
export class FormatCustomPipe implements PipeTransform {
  constructor(private formatter: FormatterService) {}

  public transform(value: DateInput | undefined, format: string) {
    return this.formatter.formatDateInput(value, format);
  }
}

@Pipe({ standalone: true, name: 'formatRange' })
export class FormatRangePipe implements PipeTransform {
  constructor(private formatter: FormatterService) {}

  public transform(value: DateInput[]) {
    return this.formatter.formatRange(value[0], value[1]);
  }
}

@Pipe({ standalone: true, name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
  constructor(private formatter: FormatterService) {}

  public transform(value?: DateInput) {
    return this.formatter.formatDate(value);
  }
}

@Pipe({ standalone: true, name: 'formatTime' })
export class FormatTimePipe implements PipeTransform {
  constructor(private formatter: FormatterService) {}

  public transform(value: DateInput) {
    return this.formatter.formatTime(value);
  }
}

@Pipe({ standalone: true, name: 'formatDateTime' })
export class FormatDateTimePipe implements PipeTransform {
  constructor(private formatter: FormatterService) {}

  public transform(value?: DateInput) {
    return this.formatter.formatDateTime(value);
  }
}

@Pipe({ standalone: true, name: 'formatCurrency' })
export class FormatCurrencyPipe implements PipeTransform {
  constructor(private formatter: FormatterService) {}

  public transform(value?: number, digits?: string) {
    return this.formatter.formatCurrency({ value, digits });
  }
}

@Pipe({ standalone: true, name: 'formatPercent' })
export class FormatPercentPipe implements PipeTransform {
  constructor(private formatter: FormatterService) {}

  public transform(value?: number, digits?: string) {
    return this.formatter.formatPercent({ value, digits });
  }
}

@Pipe({ standalone: true, name: 'formatPhone' })
export class FormatPhonePipe implements PipeTransform {
  constructor(private formatter: FormatterService) {}

  public transform(value?: string): string {
    return this.formatter.formatPhone(value);
  }
}

@Pipe({ standalone: true, name: 'timer' })
export class TimerPipe implements PipeTransform {
  public transform(value: Date, start: Date) {
    const minutesDuration = differenceInMinutes(value, start);
    const _minutes = minutesDuration % 60;
    const _hours = (minutesDuration - _minutes) / 60;
    const hours = _hours > 9 ? _hours : `0${_hours}`;
    const minutes = _minutes > 9 ? _minutes : `0${_minutes}`;
    return `${hours}:${minutes}`;
  }
}

@Pipe({ standalone: true, name: 'duration' })
export class DurationPipe implements PipeTransform {
  constructor(private duration: DurationService) {}

  public transform(
    value: DateInput | undefined,
    options: {
      end?: DateInput;
      formatOptions?: DurationStringOptions;
    } = {},
  ) {
    return this.duration.asString(value, options.end, options.formatOptions);
  }
}

@Pipe({ standalone: true, name: 'patientAge' })
export class PatientAgePipe implements PipeTransform {
  constructor(private duration: DurationService) {}
  public transform(dateOfBirth: DateInput, dateOfDeath?: DateInput) {
    return this.duration.asString(dateOfBirth, dateOfDeath, {
      years: true,
      months: true,
      days: true,
      dayStart: true,
    });
  }
}
